<template>
	<a-layout class="layout-blank">
		<a-layout-content>
			<router-view />
		</a-layout-content>
		<WsFooter />
	</a-layout>
</template>

<script>
	import WsFooter from "@/components/WsFooter";
	export default {
		components: {
			WsFooter
		},
		data() {
			return {};
		},
	};
</script>

<style>
	.layout-blank {
		height: calc(100vh - 64px);
	}
</style>
