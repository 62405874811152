<template>
  <a-layout-footer style="text-align: center">© 2020 汤为丽 版权所有 <a href="http://www.miitbeian.gov.cn/" target="_blank">粤ICP备19022565号-5</a> </a-layout-footer>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>